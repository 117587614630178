import React from "react"
import OurServices from "../../reusable/OurServices"

const SectionTwo = () => {
  return (
    <>
      <OurServices />
    </>
  )
}

export default SectionTwo
