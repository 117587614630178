import { useLayoutEffect, useState } from "react"

export default function useMaximumElementsHeight(
  elementsRef,
  initialHeight = 230
) {
  const [maximumElementHeight, setMaximumElementHeight] =
    useState(initialHeight)

  useLayoutEffect(() => {
    const elementsOffset = elementsRef.map(ref => {
      return ref.current.offsetHeight
    })
    setMaximumElementHeight(() => Math.max(...elementsOffset))
  }, [elementsRef])

  return maximumElementHeight
}
