import React from "react"
import styled from "styled-components"
import { FlexContainer, FlexItem } from "../../../styles/layout.styled"
import { P } from "../../../styles/shared.styled"
import BlogCard from "../../reusable/BlogCard"
import Link from "gatsby-link"
import { useInView } from "react-intersection-observer"
import { graphql, useStaticQuery } from "gatsby"
import { filterDummyBlog } from "../../../utils"

const SectionFourContainer = styled(FlexContainer)`
  flex-direction: column;
  background: ${({ theme }) => theme.white};
  padding: 32px 14px;
`

const BlogsContainer = styled(FlexContainer)`
  justify-content: space-between;
  max-width: 1294px;
  margin: 24px auto;
  gap: 36px;

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    flex-direction: row;
    max-width: unset;
    padding: 0 20px;
    flex-wrap: wrap;
    gap: 25px;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    gap: 36px;
  }
`

const StyledP = styled(P)`
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
    inView ? "translateX(0)" : "translateX(-700px)"};
  transition: all 0.9s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0)" : "translateX(-400px)"};
  }
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    transform: ${({ inView }) =>
      inView ? "translateX(0)" : "translateX(-200px)"};
  }
`

const StyledLink = styled(Link)`
  color: ${({ theme }) => theme.lightBlue};
  font-weight: bold;
  font-size: 12px;
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const StyledFlexItem = styled(FlexItem)`
  opacity: ${({ inView }) => (inView ? "1" : "0")};
  transform: ${({ inView }) => (inView ? "scale(1)" : "scale(0)")};
  transition: all 1s ease;
`

const query = graphql`
  {
    allContentfulBlog(limit: 4, sort: { fields: createdAt, order: DESC }) {
      nodes {
        blogTitle
        id
        createdAt(formatString: "MMMM DD YYYY")
        shortDescription
        coverImage {
          fluid(quality: 100) {
            src
            base64
            aspectRatio
            srcSet
            sizes
          }
        }
      }
    }
  }
`

const SectionFour = () => {
  const {
    allContentfulBlog: { nodes },
  } = useStaticQuery(query)

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: true,
  })

  const { ref: linkRef, inView: linkInView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: true,
  })

  return (
    <SectionFourContainer>
      <StyledP ref={ref} inView={inView} margin="0 0 14px">
        BLOG
      </StyledP>
      <StyledP ref={ref} inView={inView} fontSize="24px" fontWeight="bold">
        LATEST NEWS
      </StyledP>
      <BlogsContainer>
        {filterDummyBlog(nodes).map(
          ({
            id,
            blogTitle,
            shortDescription,
            coverImage: { fluid },
            createdAt,
          }) => (
            <BlogCard
              key={id}
              id={id}
              title={blogTitle}
              content={shortDescription}
              fluid={fluid}
              createdAt={createdAt}
            />
          )
        )}
      </BlogsContainer>
      <StyledFlexItem ref={linkRef} inView={linkInView}>
        <StyledLink to="/blog/">View More</StyledLink>
      </StyledFlexItem>
    </SectionFourContainer>
  )
}

export default SectionFour
