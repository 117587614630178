import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../styles/layout.styled"
import { Link, navigate } from "gatsby"
import Image from "gatsby-image"
import { useInView } from "react-intersection-observer"

const BlogCardContainer = styled(FlexContainer)`
  width: 30%;
  cursor: pointer;
  margin: ${({ margin }) => margin};
  flex-direction: column;
  border-radius: 8px;
  background: ${({ theme }) => theme.white};
  box-shadow: 0 0 24px rgba(18, 36, 54, 0.06);
  transform: ${({ inView }) => (inView ? "scale(1)" : "scale(0)")};
  opacity: ${({ inView }) => (inView ? "1" : "0")};
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(1.05);
  }

  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    flex-basis: 47%;
  }

  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    flex-basis: 100%;
  }
`

const BlogImage = styled(Image)`
  object-fit: cover;
  width: 100%;
  aspect-ratio: 1.8;
  border-radius: 8px 8px 0 0;
  background: ${({ theme }) => theme.darkBlue};
`

const BlogBody = styled(FlexContainer)`
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px 25px;
`

const H1 = styled.p`
  margin: 18px 0 14px;
  font-size: 20px;
  color: #2d3748;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

const BlogContent = styled.p`
  color: ${({ theme }) => `${theme.lightBlue}CC`};
  font-size: 14px;
  margin: 0;
`

const BlogCardAction = styled(FlexContainer)`
  margin-top: 39px;
  justify-content: space-between;
  align-items: center;
  > a {
    color: ${({ theme }) => theme.lightBlue};
    font-weight: bold;
    font-size: 12px;
    text-decoration: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
    transition: all 0.3s ease;
  }
`

const BlogDate = styled.span`
  font-size: 12px;
  color: ${({ theme }) => `${theme.lightBlue}CC`};
  font-weight: 100;
`

const BlogCard = ({ content, title, createdAt, id, fluid }) => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: true,
  })
  const path = `/blog/${id}`
  const handleNavigation = location => {
    navigate(location)
  }

  return (
    <BlogCardContainer
      ref={ref}
      inView={inView}
      onClick={() => handleNavigation(path)}
    >
      {fluid && <BlogImage fluid={fluid} loading="auto" fadeIn />}
      <BlogBody>
        <H1 title={title}>{title}</H1>
        <BlogContent>{content?.slice(0, 80)}...</BlogContent>
        <BlogCardAction>
          <BlogDate>{createdAt}</BlogDate>
          <Link to={path}>Read More</Link>
        </BlogCardAction>
      </BlogBody>
    </BlogCardContainer>
  )
}

export default BlogCard
