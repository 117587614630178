import React from "react"
import Seo from "../components/seo"
import LayoutRoot from "../components/LayoutRoot"
import SectionOne from "../components/Home/SectionOne/SectionOne"
import SectionTwo from "../components/Home/SectionTwo/SectionTwo"
import SectionThree from "../components/Home/SectionThree/SectionThree"
import SectionFour from "../components/Home/SectionFour/SectionFour"
import SectionFive from "../components/Home/SectionFive/SectionFive"

const IndexPage = () => {
  return (
    <LayoutRoot>
      <Seo title="Home" />
      <SectionOne />
      <SectionTwo />
      <SectionThree />
      <SectionFour />
      <SectionFive />
    </LayoutRoot>
  )
}

export default IndexPage
