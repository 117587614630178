import React from "react"
import styled from "styled-components"
import { FlexContainer } from "../../../styles/layout.styled"
import ClientCarousel from "./ClientCarousel"
import { P } from "../../../styles/shared.styled"
import { useInView } from "react-intersection-observer"

const SectionFiveContainer = styled(FlexContainer)`
  padding: 32px 14px;
  flex-direction: column;
  gap: 30px;
`

const StyledP = styled(P)`
  transform: ${({ inView }) =>
          inView ? "translateX(0)" : "translateX(1400px)"};
  transition: all 0.9s ease;
  margin: 0;
`

const SectionFive = () => {
  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: true
  })
  return (
    <SectionFiveContainer ref={ref}>
      <FlexContainer flexDirection="column" gap="14px">
        <StyledP inView={inView} color={"white"}>
          CUSTOMER REVIEWS
        </StyledP>
        <StyledP
          inView={inView}
          fontWeight="bold"
          color={"white"}
          fontSize="24px"
        >
          IN MEG WE TRUST
        </StyledP>
      </FlexContainer>
      <ClientCarousel />
    </SectionFiveContainer>
  )
}

export default SectionFive
