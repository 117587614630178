import React, { createRef } from "react"

export default function useRefs(elementsLength) {
  const elRefs = React.useRef([])

  if (elRefs.current.length !== elementsLength) {
    elRefs.current = Array(elementsLength)
      .fill(elementsLength.length)
      .map((_, i) => elRefs.current[i] || createRef())
  }

  return elRefs.current
}
