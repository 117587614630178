import React from "react"
import Background from "../../reusable/Background"
import { FlexContainer } from "../../../styles/layout.styled"
import styled from "styled-components"
import { useInView } from "react-intersection-observer"
import { graphql, navigate, useStaticQuery } from "gatsby"

const StyledH4 = styled.p`
  color: ${({ theme }) => theme.white};
  text-align: center;
  font-size: 22px;
  font-weight: 100;
  margin: 0;
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
          inView ? "translateX(0)" : "translateX(-800px)"};
  transition: all 0.9s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    font-size: 16px;
  }
`

const StyledH1 = styled.h1`
  color: ${({ theme }) => theme.white};
  font-size: 62px;
  text-align: center;
  width: 50%;
  margin: 16px 0 70px;
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) =>
          inView ? "translateX(0)" : "translateX(-800px)"};
  transition: all 1.2s ease;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 100%;
    font-size: 32px;
    margin: 16px 0 40px;
  }
`

const StyledButton = styled.button`
  background: ${({ theme }) => theme.darkBlue};
  color: ${({ theme }) => theme.orange};
  font-size: 16px;
  width: 350px;
  border-radius: 12px;
  padding: 18px;
  cursor: pointer;
  border: none;
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) => (inView ? "scale(1)" : "scale(0)")};
  transition: all 1.2s ease;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 100%;
  }
`

const getHomeBg = graphql`
    {
      homeBg: contentfulWebsiteImages(title: { eq: "homeBg" }) {
        image {
          fluid(quality: 100) {
            src
            base64
            aspectRatio
            srcSet
            sizes
          }
        }
      }
      
      categories: allContentfulCategories(sort: {fields : createdAt, order: ASC}){
        nodes {
         category,
         subCategories
      }
    }
   }
  `

const SectionOne = () => {
  const { homeBg, categories } = useStaticQuery(getHomeBg)
  const { nodes } = categories || []
  const [photography] = nodes
  const { category, subCategories } = photography || {}
  const url = `/our-work/${category?.toLowerCase()?.replace(' ', '-')}/${subCategories?.[0]?.toLowerCase()?.replace(' ', '-')}`

  const { ref, inView } = useInView({
    threshold: 0,
    triggerOnce: true,
    initialInView: false
  })

  return (
    <>
      <Background height="100vh" withColumns fluid={homeBg.image.fluid}>
        <FlexContainer
          padding="0 14px"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
          <StyledH4 ref={ref} inView={inView}>
            We Have Got Your Production Cover
          </StyledH4>
          <StyledH1 ref={ref} inView={inView}>
            Let’s Make Great Things Together
          </StyledH1>
          <StyledButton
            ref={ref}
            inView={inView}
            onClick={() => navigate(url)}
          >
            VIEW PROJECTS
          </StyledButton>
        </FlexContainer>
      </Background>
    </>
  )
}

export default SectionOne
