import React, { forwardRef } from "react"
import styled from "styled-components"
import { FlexContainer } from "../../../styles/layout.styled"
import Star from "../../../assets/svg/star.svg"

const ClientCardContainer = styled(FlexContainer)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 30px 28px;
  width: 100%;
  border: 2px solid rgba(255, 255, 255, 0.11);
  box-sizing: border-box;
  filter: drop-shadow(0px 0px 40px rgba(79, 86, 101, 0.3));
  border-radius: 7px;
  min-height: ${({ maximumElementHeight }) => maximumElementHeight}px;
  @media only screen and (max-width: ${({ theme }) => theme.tablet}) {
    width: 100%;
    padding: 30px 20px;
  }
`

const Header = styled(FlexContainer)`
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`

const Left = styled(FlexContainer)`
  justify-content: flex-start;
  width: 90%;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    width: 70%;
  }
`
const Right = styled(FlexContainer)`
  width: 10%;
  justify-content: flex-end;
  align-items: baseline;
`

const ClientPicture = styled(FlexContainer)`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: silver;
  margin-right: 18px;
  background: ${({ url }) => `url(${url})`} no-repeat;
  background-size: cover;
`

const Name = styled.p`
  font-size: 18px;
  color: ${({ theme: { white } }) => white};
  margin: 0 0 8px 0;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 12px;
  }
`
const Location = styled.span`
  font-size: 12px;
  white-space: nowrap;
  color: ${({ theme: { white } }) => `${white}66`};
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 8px;
  }
`

const Rate = styled.span`
  font-size: 16px;
  margin-right: 10px;
  color: ${({ theme: { white } }) => white};
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 12px;
  }
`

const ClientReview = styled.p`
  font-size: 16px;
  line-height: 25px;
  color: ${({ theme: { white } }) => white};
  margin: 0;
  font-family: "Gilroy-Regular", sans-serif;
  @media only screen and (max-width: ${({ theme }) => theme.mobile}) {
    font-size: 12px;
  }
`

const ClientCard = forwardRef(
  ({ customerName, company, rate, review, url, maximumElementHeight }, ref) => {
    return (
      <ClientCardContainer
        ref={ref}
        maximumElementHeight={maximumElementHeight}
      >
        <Header>
          <Left>
            <ClientPicture url={url} />
            <FlexContainer
              width="65%"
              flexDirection="column"
              alignItems="flex-start"
            >
              <Name>{customerName}</Name>
              <Location>{company}</Location>
            </FlexContainer>
          </Left>
          <Right>
            <Rate>{rate}</Rate>
            <Star />
          </Right>
        </Header>
        <ClientReview>"{review}"</ClientReview>
      </ClientCardContainer>
    )
  }
)

export default ClientCard
