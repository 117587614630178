import React from "react"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import WorkCard from "../../reusable/WorkCard"
import { FlexContainer } from "../../../styles/layout.styled"
import { P } from "../../../styles/shared.styled"
import { useInView } from "react-intersection-observer"

const SectionThreeContainer = styled(FlexContainer)`
  padding: 32px 14px 0;
  flex-direction: column;
  align-items: center;
  background: transparent;
  gap: 14px;
`

const WorkCardsContainer = styled(FlexContainer)`
  flex-wrap: wrap;
  justify-content: space-between;
`

const StyledP = styled(P)`
  opacity: ${({ inView }) => (inView ? 1 : 0)};
  transform: ${({ inView }) => inView ? "translateX(0)" : "translateX(1400px)"};
  transition: all 0.9s ease;
  margin: 0;
`

const getWorks = graphql`
{
  works: allContentfulOurWork(limit: 4, sort: { fields: createdAt, order: DESC }) {
    nodes {
      workTitle
      id
      workCategory
      description
      subCategory
      workCover {
        fluid(quality: 100) {
          src
          base64
          aspectRatio
          srcSet
          sizes
        }
      }
    }
  }
}
`

const SectionThree = () => {
  const { ref, inView } = useInView({
    threshold: 0, triggerOnce: true, initialInView: true
  })
  const { works: { nodes = [] } } = useStaticQuery(getWorks)
  return (
    <SectionThreeContainer ref={ref}>
    <StyledP inView={inView} color="white">
      OUR WORK
    </StyledP>
    <StyledP inView={inView} fontSize="24px" fontWeight="bold" color="white">
      FEATURED WORK
    </StyledP>
    <WorkCardsContainer margin="24px 0 0">
      {nodes?.map(({ id, workTitle, workCategory, workCover, subCategory }, index) => (
        <WorkCard
          key={id}
          id={id}
          title={workTitle}
          category={workCategory}
          url={workCover?.fluid}
          subCategory={subCategory}
          index={index}
        />))}
    </WorkCardsContainer>
  </SectionThreeContainer>)
}

export default SectionThree
